<template>
  <div class="licenses__wrapper">
    <img class="licenses__logo" src="@/assets/logo.svg" />

    <form class="licenses__form" @submit.prevent="handleKeyLookup">
      <label class="licenses__label" for="plugin"
        >Select Your Figma Plugin</label
      >

      <div class="licenses__selector">
        <PluginIcon class="licenses__icon" :figmaPluginId="figmaPluginId" />

        <select name="plugin" v-model="figmaPluginId">
          <option v-for="plugin in plugins" :key="plugin.id" :value="plugin.id">
            {{ plugin.label }}
          </option>
        </select>
      </div>

      <label class="licenses__label" for="license"
        >Enter Your License Key</label
      >

      <div
        v-if="!hasPrefilledKey && !success"
        class="licenses__tip licenses__tip--info"
      >
        Your license key can be found in your original purchase email receipt
        (from <em>noreply@customers.gumroad.com</em>)
      </div>

      <input
        maxlength="35"
        v-model="licenseKey"
        type="text"
        id="license"
        name="license"
        class="licenses__search"
        pattern="^[A-F0-9]{8}-[A-F0-9]{8}-[A-F0-9]{8}-[A-F0-9]{8}$"
        title="Please paste in your license key"
        placeholder="eg. 21F852A5-51012515-B43A45EE-E7E5538F"
      />

      <button
        type="submit"
        class="licenses__button button"
        :disabled="!keyInputIsValid || licenseKey == '' || fetching"
      >
        <img class="licenses__glyph" src="@/assets/search.svg" />
        <span>Lookup Your License Key</span>
      </button>

      <div v-if="fetching" class="licenses__tip licenses__tip--info">
        Please wait, loading your license key...
      </div>

      <div v-if="!keyInputIsValid" class="licenses__tip licenses__tip--warning">
        Oops! That isn't a valid license key.
      </div>

      <div
        v-if="hasFetched && !success && !fetching"
        class="licenses__tip licenses__tip--warning"
      >
        Sorry! That key wasn't found for the selected plugin.
      </div>

      <div
        v-if="hasFetched && success && !fetching"
        class="licenses__tip licenses__tip--success"
      >
        Please see your <a href="#details">details</a>,
        <a href="#seats">seats</a> and <a href="#receipts">receipts</a> below.
      </div>

      <div
        v-if="hasFetched && success && !fetching"
        class="licenses__tip licenses__tip--info"
      >
        You can
        <a @click.prevent="copyLink">
          <span v-if="!copiedUrl">copy this link</span
          ><span v-else>copied link!</span>
        </a>
        for easy access in the future.
      </div>
    </form>

    <div
      v-if="hasFetched && success && !fetching"
      id="details"
      class="licenses__card"
    >
      <h3 class="licenses__subheading licenses__subheading--manage">
        {{ product }} (<span v-if="seats_purchased == 1"
          >{{ seats_purchased }} Seat</span
        ><span v-else>{{ seats_purchased }} Seats</span>)
      </h3>

      <!-- TODO -- Add better message for 'disabled' keys on Gumroad (eg. CopyDoc: 32D3BA05-B44B4290-8E4A13E0-42DFF2EC) -->

      <div v-if="!gumroad.success" class="licenses__tip licenses__tip--warning">
        Oops! Couldn't load the details for this license; please
        <a href="https://hypermatic.com/contact" target="_blank">contact us</a>
        if you need a hand with finding any.
      </div>
      <ul v-else>
        <li>
          Total Seats:
          <b>{{ seats_purchased }}</b>
        </li>
        <li>
          Key:
          <b>{{ savedKey }}</b>
        </li>
        <li>
          Original Purchase Date:
          <b>{{ formatSaleDate(gumroad.purchase.created_at) }}</b>
        </li>
        <li>
          Purchased By:
          <b>{{ gumroad.purchase.email }}</b>
        </li>
        <li v-if="gumroad.purchase.subscription_ended_at != null">
          Subscription Ended:
          <b>{{ formatSaleDate(gumroad.purchase.subscription_ended_at) }}</b>
        </li>
        <li v-else-if="gumroad.purchase.subscription_cancelled_at != null">
          Subscription Will End:
          <b>{{
            formatSaleDate(gumroad.purchase.subscription_cancelled_at)
          }}</b>
        </li>
        <li v-else-if="gumroad.purchase.subscription_failed_at != null">
          Subscription Renewal Failed:
          <b>{{ formatSaleDate(gumroad.purchase.subscription_failed_at) }}</b>
        </li>
        <li v-else>
          Billed:
          <b v-if="gumroad.purchase.recurrence == 'yearly'">Yearly</b
          ><b v-else>Monthly</b>
        </li>
      </ul>

      <a
        v-if="gumroad.success && gumroad.purchase"
        class="licenses__button button"
        :href="`https://app.gumroad.com/subscriptions/${gumroad.purchase.subscription_id}/manage`"
        target="_blank"
        ><img class="licenses__glyph" src="@/assets/external.svg" /><span
          >Manage your subscription in Gumroad</span
        ></a
      >

      <div class="licenses__tip licenses__tip--info">
        Note: Your
        <a
          :href="`https://app.gumroad.com/subscriptions/${gumroad.purchase.subscription_id}/manage`"
          target="_blank"
          >manage subscription link</a
        >
        above will email a magic login link to:
        <b>{{ gumroad.purchase.email }}</b>
      </div>
    </div>

    <div
      v-if="hasFetched && success && !fetching"
      id="seats"
      class="licenses__card"
    >
      <div v-if="success && !users.length">
        <h3 class="licenses__subheading licenses__subheading--seats">
          Manage Figma Seats ({{ seatsAlreadyUsedCount }}/{{ seats_purchased }}
          Used)
        </h3>

        <div class="licenses__tip licenses__tip--success">
          You have
          <b
            >{{ seats_purchased - seatsAlreadyUsedCount }} unused seat<span
              v-if="seats_purchased > 1"
              >s</span
            ></b
          >
          available. To add a new Figma user, share your key below with them,
          and they can paste it into the Figma plugin to link a license seat to
          their Figma account: <b>{{ savedKey }}</b>
        </div>
      </div>
      <div v-else-if="success && users.length">
        <h3 class="licenses__subheading licenses__subheading--seats">
          Manage Figma Seats ({{ seatsAlreadyUsedCount }}/{{ seats_purchased }}
          Used)
        </h3>

        <div
          v-if="seatsAlreadyUsedCount > seats_purchased"
          class="licenses__tip licenses__tip--warning"
        >
          Sorry! There was edge case where this license was able to have more
          Figma users added to it than the total number of seats available on
          the license.<br /><br />Please see the list of
          <b>{{ seatsAlreadyUsedCount }}</b> Figma users currently linked to
          your license (which has a total of <b>{{ seats_purchased }}</b> seats
          available on it) below.<br /><br />
          You'll need to remove a minimum of
          <b>{{ seatsAlreadyUsedCount - seats_purchased + 1 }}</b> Figma users
          below from your license key to be able to add any <i>new</i> Figma
          users to it.
        </div>

        <div
          v-if="seatsAlreadyUsedCount < seats_purchased"
          class="licenses__tip licenses__tip--success"
        >
          You have
          <b
            >{{ seats_purchased - seatsAlreadyUsedCount }} unused seat<span
              v-if="seats_purchased > 1"
              >s</span
            ></b
          >
          available. To add a new Figma user, share your key below with them,
          and they can paste it into the Figma plugin to link a license seat to
          their Figma account: <b>{{ savedKey }}</b>
        </div>

        <div
          v-if="
            seatsAlreadyUsedCount > 0 && gumroad.success && gumroad.purchase
          "
          class="licenses__tip"
        >
          <span v-if="gumroad.purchase.subscription_id != ''">
            You can remove any Figma users from your license below to free up
            some seats, or
            <a
              v-if="gumroad.purchase.is_multiseat_license"
              :href="`https://app.gumroad.com/subscriptions/${gumroad.purchase.subscription_id}/manage`"
              target="_blank"
              >upgrade your subscription</a
            >
            <a v-else :href="pluginPurchaseLink" target="_blank"
              >purchase another license</a
            >
            to get more seats.
          </span>
          <span v-else>
            You can remove any Figma users from your license below to free up
            some seats, or
            <a href="https://hypermatic.com/contact" target="_blank"
              >contact us</a
            >
            for help with upgrading your subscription to purchase additional
            seats.
          </span>
        </div>

        <div class="licenses__users">
          <div class="licenses__row" v-for="user in users" :key="user.id">
            <input
              type="checkbox"
              name="users"
              :id="user.id"
              :value="user.id"
              v-model="user.checked"
            />
            <label class="licenses__seat" :for="user.id"
              ><span
                ><b>{{ user.name }}</b></span
              ><br /><small
                >Last used this key on the
                <b>{{ formatTimestamp(user.last_used) }}</b></small
              ></label
            >
          </div>
        </div>

        <button
          @click="removeSelectedUsersFromLicense"
          :disabled="!checkedUsersToRemove.length || removingSeats"
          class="licenses__button button button--destructive"
        >
          <img class="licenses__glyph" src="@/assets/trash.svg" />
          <span v-if="removingSeats"
            >Removing {{ checkedUsersToRemove.length }} Figma
            <span v-if="checkedUsersToRemove.length == 1">User</span
            ><span v-else>Users</span>...</span
          >
          <span v-else
            >Remove {{ checkedUsersToRemove.length }} Figma
            <span v-if="checkedUsersToRemove.length == 1">User</span
            ><span v-else>Users</span> from this License</span
          >
        </button>
      </div>
      <div
        v-if="!removingSeats && seatsRemovedCount > 0 && !failedRemovingSeats"
        class="licenses__tip licenses__tip--success"
      >
        Successfully removed
        <b
          >{{ seatsRemovedCount }} Figma
          <span v-if="seatsRemovedCount == 1">user</span
          ><span v-else>users</span></b
        >
        from your license!
      </div>
      <div
        v-else-if="!removingSeats && failedRemovingSeats"
        class="licenses__tip licenses__tip--warning"
      >
        Oops! The seats couldn't be removed from your license. Please
        <a href="https://hypermatic.com/contact" target="_blank">contact us</a>
        if you need help with this.
      </div>
    </div>

    <div
      v-if="hasFetched && success && !fetching"
      id="receipts"
      class="licenses__card"
    >
      <h3 class="licenses__subheading licenses__subheading--receipts">
        Your Receipts ({{ receipts.length }})
      </h3>

      <div v-if="!receipts.length" class="licenses__tip licenses__tip--warning">
        Oops! Couldn't find any receipts for this license. Please
        <a href="https://hypermatic.com/contact" target="_blank">contact us</a>
        if you need a hand with finding them.
      </div>
      <div v-else>
        <ul class="license__receipts">
          <li
            class="license__receipt"
            v-for="receipt in receipts"
            :key="receipt.sale_id"
          >
            <a
              :href="`https://app.gumroad.com/purchases/${receipt.sale_id}/receipt`"
              target="_blank"
              >{{ formatSaleDate(receipt.sale_timestamp) }} Receipt
            </a>
            <br />
            <small
              ><a
                :href="`https://app.gumroad.com/purchases/${receipt.sale_id}/generate_invoice`"
                target="_blank"
                >Generate Invoice
              </a></small
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PluginIcon from "@/components/PluginIcon.vue";

export default {
  name: "LicensesView",
  mounted() {
    if (this.$route.query.key !== undefined) {
      if (this.validKeyPattern(this.$route.query.key)) {
        this.licenseKey = this.$route.query.key;
        this.hasPrefilledKey = true;
      }
    }
    if (this.$route.query.user !== undefined) {
      this.figmaUserId = this.$route.query.user;
    }
    if (this.$route.query.plugin !== undefined) {
      this.figmaPluginId = this.$route.query.plugin;
    }
    if (this.$route.query.lookup !== undefined) {
      // Lookup on page load if query string is set (TODO - Make a form on main website Contact page for this to link over here, too)
      if (this.$route.query.lookup === "true") {
        this.handleKeyLookup();
      }
    }
  },

  components: {
    PluginIcon,
  },
  data() {
    return {
      figmaPluginId: "789009980664807964",
      figmaUserId: "",
      savedKey: "",
      savedPluginId: "",
      licenseKey: "",
      hasPrefilledKey: false,
      success: false,
      removingSeats: false,
      failedRemovingSeats: false,
      seatsRemovedCount: 0,
      users: [],
      fetching: false,
      hasFetched: false,
      copiedUrl: false,
      seats_purchased: 0,
      receipts: [],
      product: "",
      gumroad: {},
      plugins: [
        {
          id: "796124491692147799",
          label: "Bannerify",
        },
        {
          id: "1030968261424288884",
          label: "Commentful",
        },
        {
          id: "849159306117999028",
          label: "Convertify",
        },
        {
          id: "900893606648879767",
          label: "CopyDoc",
        },
        {
          id: "819688895973707151",
          label: "Crypto",
        },
        {
          id: "910671699871076601",
          label: "Emailify",
        },
        {
          id: "789010114208495133",
          label: "Favvy",
        },
        {
          id: "818960915730257192",
          label: "HyperCrop",
        },
        {
          id: "838925615018625519",
          label: "Pitchdeck",
        },
        {
          id: "816424186244407164",
          label: "Pixelay",
        },
        {
          id: "789009980664807964",
          label: "TinyImage",
        },
        {
          id: "927336249307137996",
          label: "Weblify",
        },
      ],
    };
  },
  methods: {
    validKeyPattern(key) {
      const re = /^[A-Z0-9]{8}-[A-Z0-9]{8}-[A-Z0-9]{8}-[A-Z0-9]{8}$/;
      return re.test(key);
    },
    copyLink() {
      const url = this.prefilledUrl;
      navigator.clipboard
        .writeText(url)
        .then(() => {
          // console.log("List copied to clipboard!");
          this.copiedUrl = true;
          setTimeout(() => {
            this.copiedUrl = false;
          }, 3000);
        })
        .catch(() => {
          this.copiedUrl = false;
          // console.error("Failed to copy text: ", err);
        });
    },
    formatSaleDate(timestamp) {
      const date = new Date(timestamp);
      // Function to get the ordinal suffix for a number
      const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return "th"; // Handle special case for 11th, 12th, 13th, etc.
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };

      // Get the day, month, and year
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Construct the final date string with the ordinal suffix
      return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    },
    formatTimestamp(last_used) {
      // Extract the seconds and nanoseconds
      const seconds = last_used._seconds;
      const nanoseconds = last_used._nanoseconds;

      // Convert seconds to milliseconds
      const millisecondsFromSeconds = seconds * 1000;

      // Convert nanoseconds to milliseconds
      const millisecondsFromNanoseconds = Math.floor(nanoseconds / 1000000);

      // Combine the two to get the total milliseconds
      const totalMilliseconds =
        millisecondsFromSeconds + millisecondsFromNanoseconds;

      // Create the JavaScript Date object
      const date = new Date(totalMilliseconds);

      // Format the date
      return this.formatDate(date);
    },

    formatDate(date) {
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Get the appropriate day suffix (st, nd, rd, th)
      const daySuffix = this.getDaySuffix(day);

      return `${day}${daySuffix} ${month} ${year}`;
    },

    getDaySuffix(day) {
      if (day > 3 && day < 21) return "th"; // Exceptions for 11th, 12th, 13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    async removeSelectedUsersFromLicense() {
      this.seatsRemovedCount = 0;
      this.removingSeats = false;
      this.failedRemovingSeats = false;
      const count = this.checkedUsersToRemove.length;
      const license_key = this.savedKey;
      if (count > 0 && this.validKeyPattern(license_key)) {
        if (
          confirm(
            `Are you sure you want to remove ${count} Figma user's seats from your ${this.product} license key?`
          ) == true
        ) {
          this.removingSeats = true;

          const joinedSeats = this.checkedUsersToRemove.join("|");

          const response = await fetch(
            `https://us-central1-figmatic-admin.cloudfunctions.net/removeFigmaUserSeats?k=${license_key}&s=${joinedSeats}`,
            {
              method: "GET",
            }
          )
            .then((response) => response.json())
            .then((data) => data)
            .catch((error) => error);

          const { success, user_ids_removed } = response;

          this.removingSeats = false;

          if (success) {
            // Remove from users array client-side
            this.failedRemovingSeats = false;
            this.seatsRemovedCount = user_ids_removed.length;
            this.users = this.users.filter(
              (user) => !user_ids_removed.includes(`${user.id}`)
            );
          } else {
            this.seatsRemovedCount = 0;
            this.failedRemovingSeats = true;
          }
        }
      }
    },
    async lookupLicense(license_key) {
      const product_id = this.figmaPluginId;
      // const product_id = "910671699871076601";

      return fetch(
        `https://us-central1-figmatic-admin.cloudfunctions.net/lookupFigmaPluginKeySeats?k=${license_key}&p=${product_id}`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => error);
    },
    resetData() {
      this.success = false;
      this.users = [];
      this.seats_purchased = 0;
      this.receipts = [];
      this.product = "";
      this.gumroad = {};
      this.savedKey = "";
      this.savedPluginId = "";
    },
    async handleKeyLookup() {
      if (this.keyInputIsValid) {
        this.resetData();
        this.hasFetched = false;
        this.fetching = true;

        const key = this.licenseKey;

        const {
          success,
          users,
          seats_purchased,
          receipts,
          product,
          permalink,
        } = await this.lookupLicense(key);

        this.seats_purchased = seats_purchased;
        this.receipts = receipts;
        this.product = product;
        this.success = success;
        this.users = users;
        this.savedKey = key;
        this.savedPluginId = this.figmaPluginId;

        // test emailify with: F1918799-AA414069-A9AB44B4-0348069C

        const gumroadData = await this.getGumroadData(permalink, key);
        this.gumroad = gumroadData;

        this.fetching = false;
        this.hasFetched = true;

        if (success) {
          try {
            const newUrl = this.prefilledUrl;
            // Use the History API to replace the URL
            history.replaceState(null, "", newUrl);
            // console.log(`URL changed to: ${newUrl}`);
          } catch (error) {
            // console.error("Failed to change URL:", error);
          }
        }
      }
    },
    async getGumroadData(product_id, license_key) {
      return fetch("https://api.gumroad.com/v2/licenses/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          product_id,
          license_key,
        }),
      })
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => error);
    },
  },
  computed: {
    keyInputIsValid() {
      if (this.licenseKey.trim() === "") {
        return true;
      }
      return this.validKeyPattern(this.licenseKey);
    },
    prefilledUrl() {
      return `https://license.hypermatic.com/?key=${this.savedKey}&plugin=${this.savedPluginId}&lookup=true`;
    },
    pluginPurchaseLink() {
      switch (this.figmaPluginId) {
        case "796124491692147799":
          return "https://hypermatic.gumroad.com/l/bannerify?quantity=1&yearly=true";
        case "1030968261424288884":
          return "https://hypermatic.gumroad.com/l/commentful?quantity=1&yearly=true";
        case "849159306117999028":
          return "https://hypermatic.gumroad.com/l/convertify?quantity=1&yearly=true";
        case "900893606648879767":
          return "https://hypermatic.gumroad.com/l/copydoc?quantity=1&yearly=true";
        case "819688895973707151":
          return "https://hypermatic.gumroad.com/l/crypto?quantity=1&yearly=true";
        case "910671699871076601":
          return "https://hypermatic.gumroad.com/l/emailify?quantity=1&yearly=true";
        case "789010114208495133":
          return "https://hypermatic.gumroad.com/l/favvy?quantity=1&yearly=true";
        case "818960915730257192":
          return "https://hypermatic.gumroad.com/l/hypercrop?quantity=1&yearly=true";
        case "838925615018625519":
          return "https://hypermatic.gumroad.com/l/pitchdeck?quantity=1&yearly=true";
        case "816424186244407164":
          return "https://hypermatic.gumroad.com/l/pixelay?quantity=1&yearly=true";
        case "789009980664807964":
          return "https://hypermatic.gumroad.com/l/tinyimage?quantity=1&yearly=true";
        case "927336249307137996":
          return "https://hypermatic.gumroad.com/l/weblify?quantity=1&yearly=true";
        default:
          return "https://hypermatic.gumroad.com/";
      }
    },
    seatsAlreadyUsedCount() {
      return this.users.length;
    },
    checkedUsersToRemove() {
      const checkedUsers = this.users.filter((user) => user.checked);

      let ids = [];

      if (!checkedUsers.length) {
        return ids;
      }

      checkedUsers.forEach((u) => {
        ids.push(u.id);
      });

      return ids;
    },
  },
};
</script>

<style lang="scss">
.licenses__search {
  width: 100%;
  padding: 8px;
  padding-left: 56px;
  border-radius: 4px;
  min-height: 44px;
  appearance: none;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 12px;
  color: rgb(26, 31, 54);
  outline: 0;
  border: 1px solid #ccc;
  transition: all 220ms ease;
  background-color: rgb(255, 255, 255);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M24 11.5c0 3.613-2.951 6.5-6.475 6.5-2.154 0-4.101-1.214-5.338-3h-2.882l-1.046-1.013-1.302 1.019-1.362-1.075-1.407 1.081-4.188-3.448 3.346-3.564h8.841c1.145-1.683 3.104-3 5.339-3 3.497 0 6.474 2.866 6.474 6.5zm-10.691 1.5c.98 1.671 2.277 3 4.217 3 2.412 0 4.474-1.986 4.474-4.5 0-2.498-2.044-4.5-4.479-4.5-2.055 0-3.292 1.433-4.212 3h-9.097l-1.293 1.376 1.312 1.081 1.38-1.061 1.351 1.066 1.437-1.123 1.715 1.661h3.195zm5.691-3.125c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat !important;
  background-size: 24px 24px !important;
  background-position: center left 16px !important;

  &:active,
  &:focus {
    border: 1px solid #555;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
}

.licenses__logo {
  height: 45px;
  width: auto;
  display: block;
  margin: auto;
  margin-bottom: 24px;
}

.licenses__label {
  font-weight: bold;
  text-align: left;
  display: block;
  color: #000;
  margin-bottom: 4px;
}

.licenses__wrapper {
  max-width: 960px;
  margin: auto;
  padding: 24px;
}

.licenses__subheading {
  display: inline-block;
  padding-left: 38px;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 24px 24px;
}

.licenses__subheading--manage {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M22 4h-20c-1.104 0-2 .896-2 2v12c0 1.104.896 2 2 2h20c1.104 0 2-.896 2-2v-12c0-1.104-.896-2-2-2zm0 13.5c0 .276-.224.5-.5.5h-19c-.276 0-.5-.224-.5-.5v-6.5h20v6.5zm0-9.5h-20v-1.5c0-.276.224-.5.5-.5h19c.276 0 .5.224.5.5v1.5zm-9 6h-9v-1h9v1zm-3 2h-6v-1h6v1zm10-2h-3v-1h3v1z'/%3E%3C/svg%3E");
}

.licenses__subheading--seats {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M20.5 13c-1.932 0-3.5 1.567-3.5 3.5s1.568 3.5 3.5 3.5 3.5-1.567 3.5-3.5-1.568-3.5-3.5-3.5zm1.5 4h-3v-1h3v1zm-13.001-5.9c0 1.692-.766 2.9-1.206 3.9h-1.397c.227-1 1.954-3.415 1.021-4.982-.55-.923-2.272-.924-2.819-.015-.507.841-.24 2.417.712 4.215.518.978.374 1.734.162 2.197-.406.889-1.303 1.317-2.316 1.612-2.01.588-1.825.055-1.825 1.973h-1.329l-.002-.618c0-1.262.099-1.989 1.59-2.333 1.719-.397 3.319-.745 2.545-2.209-2.361-4.457-.627-6.84 1.866-6.84 1.687 0 2.998 1.09 2.998 3.1zm5.691 1.395c.607 1.146.447 2.016.206 2.543-.66 1.445-2.472 1.863-4.39 2.305-1.252.29-1.172.588-1.172 2.657h-1.331l-.003-.825c0-1.681.132-2.652 2.119-3.111 2.293-.53 4.427-.994 3.394-2.946-3.147-5.941-.835-9.118 2.488-9.118 3.164 0 5.337 2.879 3.041 8h-1.483c1.159-2.325 1.428-4.326.708-5.533-.902-1.517-3.617-1.509-4.512-.022-.768 1.273-.426 3.478.935 6.05z'/%3E%3C/svg%3E");
}

.licenses__subheading--receipts {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='12' viewBox='0 0 384 512' class='faq__icon'%3E%3Cpath d='M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0 0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z'%3E%3C/path%3E%3C/svg%3E");
}

.license__receipts {
  list-style: none;
  padding: 0;
  margin: 0;
}

.license__receipt {
  margin-bottom: 8px;
  border: 1px solid #eee;
  padding: 8px 16px;
  border-radius: 4px;

  small {
    a {
      font-weight: 400;
    }
  }
}

.licenses__button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.licenses__glyph {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.licenses__card,
.licenses__form {
  margin: auto;
  background: #fff;
  padding: 32px;
  border-radius: 8px;
  max-width: 480px;
  border: 1px solid #eee;
  margin-bottom: 32px;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.licenses__selector {
  margin-bottom: 24px;
  position: relative;
}

.licenses__icon {
  position: absolute;
  pointer-events: none;
  left: 14px;
  top: 9px;
  width: 32px;
  height: 32px;
}

.licenses__card {
  padding-top: 16px;
}

.licenses__users {
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 4px;
  margin: 16px 0;
}

.licenses__row {
  display: flex;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  padding: 8px;
  cursor: pointer;

  input {
    cursor: pointer;
  }

  &:hover {
    background-color: rgba(240, 249, 255, 0.5);
  }
  &:last-child {
    border: 0;
  }
}

.licenses__seat {
  flex: 1;
  cursor: pointer;
  padding-left: 8px;
}

.licenses__tip {
  padding: 8px;
  padding-left: 42px;
  border-radius: 4px;
  font-size: 14px;
  margin: 8px 0;
  background-repeat: no-repeat;
  background-position: center left 12px;
  background-size: 18px 18px;
  background-color: rgba(240, 249, 255, 0.5);
  border: 1px solid rgba(14, 165, 233, 0.2);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_2)'%3E%3Cpath d='M11.9994 0C18.6227 0 24 5.37734 24 11.9994C24 18.6227 18.6227 24 11.9994 24C5.37734 24 0 18.6227 0 11.9994C0 5.37734 5.37734 0 11.9994 0ZM11.9994 1.80045C6.36999 1.80045 1.80045 6.36999 1.80045 11.9994C1.80045 17.6288 6.36999 22.1995 11.9994 22.1995C17.6288 22.1995 22.1995 17.6288 22.1995 11.9994C22.1995 6.36999 17.6288 1.80045 11.9994 1.80045ZM11.9994 9.6024C11.5025 9.6024 11.0992 10.0057 11.0992 10.5026V17.1043C11.0992 17.6012 11.5025 18.0045 11.9994 18.0045C12.4963 18.0045 12.8996 17.6012 12.8996 17.1043V10.5026C12.8996 10.0057 12.4963 9.6024 11.9994 9.6024ZM11.997 6.0015C12.6596 6.0015 13.1973 6.53923 13.1973 7.2018C13.1973 7.86437 12.6596 8.4021 11.997 8.4021C11.3344 8.4021 10.7967 7.86437 10.7967 7.2018C10.7967 6.53923 11.3344 6.0015 11.997 6.0015Z' fill='%230EA5E9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_2'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");

  a {
    cursor: pointer;
  }
}

.licenses__tip--success {
  background-color: rgba(236, 253, 245, 0.5);
  border-color: rgba(16, 185, 129, 0.2);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4_4)'%3E%3Cpath d='M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM10.041 17L5.541 12.681L6.936 11.246L10.016 14.183L17.037 7L18.459 8.409L10.041 17Z' fill='%2316A34A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4_4'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.licenses__tip--warning {
  background-color: rgba(255, 251, 235, 0.5);
  border-color: rgba(245, 158, 11, 0.2);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5.177L20.631 21H3.369L12 5.177ZM12 1L0 23H24L12 1ZM11 10H13V16H11V10ZM12 19.75C11.311 19.75 10.75 19.19 10.75 18.5C10.75 17.81 11.311 17.25 12 17.25C12.689 17.25 13.25 17.81 13.25 18.5C13.25 19.19 12.689 19.75 12 19.75Z' fill='%23FBBF24'/%3E%3C/svg%3E%0A");
}
</style>
